// import React, { useEffect, useState, Suspense } from "react";
// import { Route, Routes, useNavigate } from "react-router-dom";
// import "./App.css";
// import ScrollToTop from "./helper/ScrollToTop";
// import { ThemeProvider } from "@mui/material";
// import { theme } from "./helper/Theme";
// import Loader from "./components/Common/Loader/Loader";
// import Auditors from "./pages/Auditors/Auditors.jsx";
// import AuditorsDetails from "./pages/Auditors/AuditorsDetails.jsx";
// import Admin from "./pages/Admin & Role/Admin.jsx";
// import Role from "./pages/Admin & Role/Role.jsx";
// import Addrole from "./pages/Admin & Role/Addrole.jsx";
// import FormManagement from "./pages/Form Management/FormManagement.jsx";
// import View from "./pages/View/View.jsx";
// import Submitform from "./pages/Submitform/Submitform.jsx";
// import FormCreation from "./pages/Form Creation/FormCreation.jsx";
// import { useDispatch, useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
// import { useUserNotificationSubscribeHook } from "./Notification.js";
// import { loadUser } from "./Service/auth.service";
// import { loginUserApi, logoutApi } from "./state/Action/authAction";
// import Notification from "./components/Common/Notification/Notification.js";
// import { Modal } from '../src/components/Common';
// import PrivateRoute from "./Routes/PrivateRoute";
// import Group from "./pages/Group/Group.jsx";
// // import IframeDropdown from "./pages/Form Creation/DropDown/IframeDropdown.jsx";
// // import IframeGridsingle from "./pages/Form Creation/GridForm/GridSingle/IframeGridsingle.jsx";
// // import IframeGridMulti from "./pages/Form Creation/GridForm/GridMulti/IframeGridMulti.jsx";
// // import IframeGridScale from "./pages/Form Creation/GridForm/GridScale/IframeGridScale.jsx";
// // import IframeShow from "./pages/Form Creation/IframeShow.js";
// import SubmittedFormView from "./pages/Submitform/SubmittedFormView.jsx";

// const Login = React.lazy(() => import("./pages/Login/Login"));
// const Layout = React.lazy(() => import("./components/layouts/Layout"));
// const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard.js"));
// const IframeTextForm = React.lazy(() => import("./pages/Form Creation/TextForm/IframeTextform.jsx"));
// const IframeMultiSelect = React.lazy(() => import("./pages/Form Creation/MultiSelect/IframeMultiSelect.jsx"));
// const IframeScale = React.lazy(() => import("./pages/Form Creation/Scale/IframeScale.jsx"));
// const NoDataFound = React.lazy(() => import("./pages/NoDataFound/NoDataFound.js"));

// const pages = [
//   { name: "Dashboard", path: "dashboard", element: <Dashboard /> },
//   { name: "Auditor", path: "auditors", element: <Auditors /> },
//   { name: "Auditor", path: "auditor-details", element: <AuditorsDetails /> },
//   { name: "Admin", path: "admin", element: <Admin /> },
//   { name: "Role", path: "role", element: <Role /> },
//   { name: "Role", path: "addrole", element: <Addrole /> },
//   { name: "From Management", path: "form-management", element: <FormManagement /> },
//   { name: "view", path: "view", element: <View /> },
//   { name: "Submitted Form", path: "submited-form", element: <Submitform /> },
//   { name: "From Management", path: "formcreation", element: <FormCreation /> },
//   { name: "From Management", path: "form-update", element: <FormCreation /> },
//   { name: "Group", path: "group", element: <Group /> },
//   { name: "Submitted Form", path: "form-view", element: <SubmittedFormView /> }
// ];

// function App() {
//   const [showNotification, setShowNotification] = useState({
//     show: false,
//     type: '',
//     message: ''
//   });
//   const { registerAndSubscribe } = useUserNotificationSubscribeHook();

//   const [isModalOpen, setIsModalOpen] = useState({
//     open: false,
//     currentComponent: "",
//     para: '', head: '',
//     isEdit: false,
//     isView: false,
//     id: ''
//   });
//   const [roleName, setRoleName] = useState("admin");

//   const [isLoading, setIsLoading] = useState(true);
//   const [isUserLoaded, setIsUserLoaded] = useState(false);

//   const location = useLocation();
//   const { pathname } = location;
//   const dispatch = useDispatch();
//   const Navigate = useNavigate();

//   const { show } = useSelector(state => state?.root?.notification);
//   const { user } = useSelector((state) => state?.root?.auth);
//   const { auth } = useSelector((state) => state?.root);

//   useEffect(() => {
//     if (pathname == "/" && user && user?.roleName && user?.roleName) {
//       // Navigate("/dashboard");
//     }
//     setRoleName(user && user?.roleName);
//   }, [user, pathname]);

//   const handelLogout = () => {
//     setIsModalOpen({
//       open: false,
//       currentComponent: "",
//       para: '',
//       head: '',
//       id: ''
//     });
//     localStorage.removeItem('token');
//     dispatch(logoutApi());

//     Navigate('/');
//   }

//   useEffect(() => {
//     if (auth && auth?.isExpired) {
//       setIsModalOpen({
//         open: true,
//         currentComponent: "logout",
//         para: 'Logged out due to inactivity. Please log in again to continue.',
//         head: 'Session Expired',
//       });
//     }
//   }, [auth]);

//   const getUsetData = async (data) => {
//     let res = await loadUser(data);
//     if (res?.status) {
//       registerAndSubscribe(res?.data && res?.data?.id);
//       dispatch(loginUserApi(res?.data)).then(() => {
//         setIsUserLoaded(true);
//       }).catch(() => {
//         setIsUserLoaded(true);
//       })
//     } else {
//       setIsUserLoaded(true);
//     }
//   };


//   useEffect(() => {
//     // if (pathname !== "/") {
//     const token = localStorage.getItem("token");
//     const payload = {
//       token: token,
//     };
//     getUsetData(payload);
//     // }

//   }, []);


//   useEffect(() => {
//     if (show && show.show) {
//       setShowNotification(show);
//     }
//   }, [show]);
//   const permission =
//     user &&
//     user.permission &&
//     user.permission.find((permission) => {
//       return permission.pageName == "dashboard";
//     });

//   return (
//     <>
//       {/* <ScrollToTop>
//         <ThemeProvider theme={theme}>
//           <div className="background_color">
//             <Suspense fallback={<Loader />}>
//               <Routes>
//                 <Route path="/" element={
//                   !isUserLoaded ? (
//                     <Loader />
//                   ) : user && user?.role ? (
//                     <Layout>
//                       <Dashboard />
//                     </Layout>
//                   ) : (
//                     <Login />
//                   )
//                 } />
//               </Routes>
//             </Suspense>

//             <Routes>
//               {pages?.map((page, index) => {
//                 const { name, path, element } = page;
//                 const permission =
//                   user &&
//                   user.permission &&
//                   user.permission.find((permission) => {
//                     return permission.pageName == name;
//                   });
//                 return (
//                   <Route
//                     key={index}
//                     exact
//                     path={`/${path}`}
//                     name={name}
//                     element={
//                       <PrivateRoute>
//                         <Layout>{element}</Layout>
//                       </PrivateRoute>
//                     }
//                   />
//                 );
//               })}
//               <Route path="*" element={<NoDataFound />} />

//             </Routes>

//           </div>
//           {showNotification && showNotification.show ? <Notification show={showNotification} setShow={setShowNotification} /> : null}
//           <Modal
//             modalOpen={isModalOpen}
//             setIsModalOpen={setIsModalOpen}
//             handleClose={() => {
//               return;
//             }}
//             handelClick={handelLogout}
//           />
//         </ThemeProvider>
//       </ScrollToTop> */}
//       <ScrollToTop>
//         <ThemeProvider theme={theme}>
//           <div className="background_color">
//             <Suspense fallback={<Loader />}>
//               <Routes>
//                 {/* Main Route to handle '/' */}
//                 <Route
//                   path="/"
//                   element={
//                     !isUserLoaded ? (
//                       <Loader />
//                     ) : user && user?.role ? (
//                       <Layout>
//                         <Dashboard />
//                       </Layout>
//                     ) : (
//                       <Login />
//                     )
//                   }
//                 />

//                 {/* Dynamic routes based on pages array */}
//                 {pages?.map((page, index) => {
//                   const { name, path, element } = page;
//                   const permission =
//                     user &&
//                     user.permission &&
//                     user.permission.find((permission) => {
//                       return permission.pageName == name;
//                     });
//                   if ((permission && permission.readP === 1) || user.roleName == "Super Admin") {
//                     return (
//                       <Route
//                         key={index}
//                         exact
//                         path={`/${path}`}
//                         name={name}
//                         element={
//                           <PrivateRoute>
//                             <Layout>{element}</Layout>
//                           </PrivateRoute>
//                         }
//                       />
//                     );
//                   }
//                 })}

//                 {/* 404 Route to handle any unmatched paths */}
//                 <Route path="*" element={<NoDataFound />} />
//               </Routes>
//             </Suspense>

//             {/* Notification and Modal Components */}
//             {showNotification && showNotification.show ? (
//               <Notification show={showNotification} setShow={setShowNotification} />
//             ) : null}
//             <Modal
//               modalOpen={isModalOpen}
//               setIsModalOpen={setIsModalOpen}
//               handleClose={() => {
//                 return;
//               }}
//               handelClick={handelLogout}
//             />
//           </div>
//         </ThemeProvider>
//       </ScrollToTop>

//     </>
//   );
// }

// export default App;

import React, { useEffect, useState, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./helper/ScrollToTop";
import { ThemeProvider } from "@mui/material";
import { theme } from "./helper/Theme";
import Loader from "./components/Common/Loader/Loader";
import Auditors from "./pages/Auditors/Auditors.jsx";
import AuditorsDetails from "./pages/Auditors/AuditorsDetails.jsx";
import Admin from "./pages/Admin & Role/Admin.jsx";
import Role from "./pages/Admin & Role/Role.jsx";
import Addrole from "./pages/Admin & Role/Addrole.jsx";
import FormManagement from "./pages/Form Management/FormManagement.jsx";
import FormDownload from "./pages/Form Management/DownloadForms.jsx";
import View from "./pages/View/View.jsx";
import Submitform from "./pages/Submitform/Submitform.jsx";
import FormCreation from "./pages/Form Creation/FormCreation.jsx";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useUserNotificationSubscribeHook } from "./Notification.js";
import { loadUser } from "./Service/auth.service";
import { loginUserApi, logoutApi } from "./state/Action/authAction";
import Notification from "./components/Common/Notification/Notification.js";
import { Modal } from '../src/components/Common';
import PrivateRoute from "./Routes/PrivateRoute";
import Group from "./pages/Group/Group.jsx";
import SubmittedFormView from "./pages/Submitform/SubmittedFormView.jsx";

const Login = React.lazy(() => import("./pages/Login/Login"));
const Layout = React.lazy(() => import("./components/layouts/Layout"));
const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard.js"));
const NoDataFound = React.lazy(() => import("./pages/NoDataFound/NoDataFound.js"));

const pages = [
  { name: "Dashboard", path: "dashboard", element: <Dashboard /> },
  { name: "Auditor", path: "auditors", element: <Auditors /> },
  { name: "Auditor", path: "auditor-details", element: <AuditorsDetails /> },
  { name: "Admin", path: "admin", element: <Admin /> },
  { name: "Role", path: "role", element: <Role /> },
  { name: "Role", path: "addrole", element: <Addrole /> },
  { name: "From Management", path: "form-management", element: <FormManagement /> },
  { name: "Form Download", path: "form-download", element: <FormDownload /> },
  { name: "view", path: "view", element: <View /> },
  { name: "Submitted Form", path: "submited-form", element: <Submitform /> },
  { name: "From Management", path: "formcreation", element: <FormCreation /> },
  { name: "From Management", path: "form-update", element: <FormCreation /> },
  { name: "Group", path: "group", element: <Group /> },
  { name: "Submitted Form", path: "form-view", element: <SubmittedFormView /> }
];

function App() {
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });
  const { registerAndSubscribe } = useUserNotificationSubscribeHook();

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: '', head: '',
    isEdit: false,
    isView: false,
    id: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const { show } = useSelector(state => state?.root?.notification);
  const { user } = useSelector((state) => state?.root?.auth);
  const { auth } = useSelector((state) => state?.root);

  useEffect(() => {
    if (pathname == "/" && user && user?.roleName && user?.roleName) {
      // Navigate("/dashboard");
    }
  }, [user, pathname]);

  const handelLogout = () => {
    setIsModalOpen({
      open: false,
      currentComponent: "",
      para: '',
      head: '',
      id: ''
    });
    localStorage.removeItem('token');
    dispatch(logoutApi());
    Navigate('/');
  };

  const getUsetData = async (data) => {
    let res = await loadUser(data);
    if (res?.status) {
      registerAndSubscribe(res?.data?.id);
      dispatch(loginUserApi(res?.data)).then(() => setIsUserLoaded(true));
    } else {
      setIsUserLoaded(true);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const payload = { token };
    getUsetData(payload);
  }, []);

  useEffect(() => {
    if (show && show.show) {
      setShowNotification(show);
    }
  }, [show]);

  return (
    <ScrollToTop>
      <ThemeProvider theme={theme}>
        <div className="background_color">
          <Suspense fallback={<Loader />}>
            <Routes>
              {/* Main Route */}
              <Route
                path="/"
                element={
                  !isUserLoaded ? (
                    <Loader />
                  ) : user && user.role ? (
                    <Layout>
                      <Dashboard />
                    </Layout>
                  ) : (
                    <Login />
                  )
                }
              />

              {/* Routes rendered only when user data is loaded */}
              {isUserLoaded && pages.map((page, index) => {
                const { name, path, element } = page;
                const permission = user?.permission?.find((p) => p.pageName === name);

                return ((permission && permission.readP === 1) || user.roleName === "Super Admin") && (
                  <Route
                    key={index}
                    path={`/${path}`}
                    element={
                      <PrivateRoute>
                        <Layout>{element}</Layout>
                      </PrivateRoute>
                    }
                  />
                );
              })}

              {/* 404 Route */}
              {isUserLoaded && <Route path="*" element={<NoDataFound />} />}
            </Routes>
          </Suspense>

          {showNotification && showNotification.show && (
            <Notification show={showNotification} setShow={setShowNotification} />
          )}
          <Modal
            modalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            handelClick={handelLogout}
          />
        </div>
      </ThemeProvider>
    </ScrollToTop>
  );
}

export default App;
