import axios from "axios";

axios.defaults.withCredentials = false;
const AxiosWithoutToken = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  baseURL: "https://metrixapi.metrixra.com/api/v1/",

  crossDomain: false,
});
console.log(AxiosWithoutToken);


export default AxiosWithoutToken;



