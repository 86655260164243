import React from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

const SkipLogic = ({
  logicQuestionSelecteData,
  forms,
  setForms,
  index,
  skipLogicData,
  skipLogicCondition,
  setSkipLogicCondition,
  displaylogicQuestionSelecteData,
  setDisplaylogicQuestionSelecteData,
  setDisplayLogicCondition,
  displayLogicCondition,
}) => {
  const isDisplayLogicResponded =
    forms[index] &&
    (forms[index].displayLogicCondition === "Responded selected" ||
      forms[index].displayLogicCondition === "Responded Not selected");

  const answerType = forms[forms[index]?.dlogicIndex]?.answerType;
  const isAnswerTypeSingle = answerType === "single";
  const isAnswerTypeMultiple = answerType === "multiple";

  const isSkipLogicResponded =
    forms[index] &&
    (forms[index].skipLogicCondition === "Responded selected" ||
      forms[index].skipLogicCondition === "Responded Not selected");

  const skipAnswerType = forms[forms[index]?.logicIndex]?.answerType;
  const isSkipAnswerTypeSingle = skipAnswerType === "single";
  const isSkipAnswerTypeMultiple = skipAnswerType === "multiple";

  return (
    <>
      <Box>
        <span className="boldfont">DISPLAY LOGIC</span>
        <Box className="logictab">
          <Typography>Display the Question Only</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              flexDirection: "row",
              textTransform: "capitalize",
              gap: 1,
            }}
          >
            <span style={{ fontSize: "16px" }}>If</span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "10px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Select
                      placeholder="yuyuyu"
                      onChange={(e) => {
                        let newArray = [...forms];
                        newArray[index].dlogicIndex = e.target.value;
                        newArray[index].dlogicSelectIndex = index;

                        newArray[index].displayLogicCondition = "";
                        newArray[index].dlogicQuestionType =
                          logicQuestionSelecteData[e.target.value].type ==
                          "text"
                            ? "textEmail"
                            : logicQuestionSelecteData[e.target.value].type;
                        setForms(newArray);

                        let newArray2 = [...displayLogicCondition];
                        newArray2[index].questionIndex = e.target.value;
                        newArray2[index].displayIndex = index;
                        newArray2[index].condition = "";
                        newArray2[index].questionType =
                          logicQuestionSelecteData[e.target.value].type;
                        newArray2[index].value = "";
                        setDisplayLogicCondition(newArray2);
                      }}
                      value={forms[index].dlogicIndex}
                    >
                      {/* {forms?.map(
                        (item, i) =>
                          index > i &&
                          item.type !== "Metrix" && (
                            <MenuItem key={i} value={i}>
                              Q{i + 1} -{" "}
                              <span style={{ color: "#9F9F9F" }}>
                                {item.question}
                              </span>
                            </MenuItem>
                          )
                      )} */}
                      {forms.map(
                        (form, i) =>
                          form.type !== "Metrix" && (
                            <MenuItem key={i} value={i}>
                              {" "}
                              Q-{i + 1} &nbsp;
                              <span style={{ color: "#9F9F9F" }}>
                                {form.question
                                  ? form.question.substring(0, 30) + "..."
                                  : ""}
                              </span>
                            </MenuItem>
                          )
                        // )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <Select
                      onChange={(e) => {
                        let newArray = [...forms];
                        newArray[index].displayLogicCondition = e.target.value;
                        newArray[
                          forms[index]?.dlogicSelectIndex
                        ].displayLogicValue = "";
                        newArray[
                          forms[index]?.dlogicSelectIndex
                        ].displayLogicValues = [];
                        newArray[
                          forms[index]?.dlogicSelectIndex
                        ].displayLogicValue = "";
                        setForms(newArray);

                        let newArray2 = [...displayLogicCondition];
                        newArray2[index].condition = e.target.value;
                        newArray2[index].value = "";
                        newArray2[index].values = [];
                        newArray2[index].answerType = "";
                        newArray2[index].displayLogicValue = "";

                        setDisplayLogicCondition(newArray2);
                      }}
                      value={forms[index].displayLogicCondition}
                    >
                      {skipLogicData &&
                      Array.isArray(skipLogicData) &&
                      skipLogicData.length > 0 &&
                      forms[index] &&
                      forms[index].logicQuestionType &&
                      skipLogicData.some(
                        (item) => item.type == forms[index].dlogicQuestionType
                      )
                        ? skipLogicData
                            .filter(
                              (item) =>
                                item.type === forms[index].dlogicQuestionType
                            )[0]
                            ?.data.map((item, i) => (
                              <MenuItem key={i} value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    {(forms[index].displayLogicCondition === "Starts with" ||
                      forms[index].displayLogicCondition === "Ends with" ||
                      forms[index].displayLogicCondition === "Contains" ||
                      forms[index].displayLogicCondition ===
                        "Does not contain" ||
                      forms[index].displayLogicCondition === "Equal to" ||
                      forms[index].displayLogicCondition === "Not equal to" ||
                      forms[index].displayLogicCondition === "Less than" ||
                      forms[index].displayLogicCondition ===
                        "Greater than") && (
                      <TextField
                        type="text"
                        onChange={(e) => {
                          let newArray = [...forms];
                          newArray[
                            forms[index]?.dlogicSelectIndex
                          ].displayLogicValue = e.target.value;
                          setForms(newArray);

                          let newArray2 = [...displayLogicCondition];
                          newArray2[index].value = e.target.value;
                          setDisplayLogicCondition(newArray2);
                        }}
                        value={
                          forms[forms[index]?.dlogicSelectIndex]
                            ?.displayLogicValue || ""
                        }
                        placeholder=""
                      />
                    )}
                    {isDisplayLogicResponded && isAnswerTypeSingle && (
                      <Select
                        placeholder="yuyuyu"
                        onChange={(e) => {
                          let newArray = [...forms];
                          newArray[index].displayLogicValue = e.target.value;
                          setForms(newArray);

                          let newArray2 = [...displayLogicCondition];
                          newArray2[index].value = e.target.value;
                          newArray2[index].answerType = "single";
                          setDisplayLogicCondition(newArray2);
                        }}
                        value={forms[index].displayLogicValue}
                      >
                        {forms[forms[index]?.dlogicIndex]?.data?.map(
                          (item, i) => (
                            <MenuItem key={i} value={item?.value}>
                              {item.value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    )}
                    {isDisplayLogicResponded && isAnswerTypeMultiple && (
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={
                          forms[forms[index]?.dlogicIndex]?.data.filter(
                            (ans) => ans.value != ""
                          ) || []
                        }
                        getOptionLabel={(option) =>
                          option.value != "" ? option.value : ""
                        }
                        // filterSelectedOptions
                        onChange={(e, value) => {
                          let newArray = [...forms];
                          newArray[index].displayLogicValues = value.map(
                            (val) => val.ids
                          );
                          setForms(newArray);
                          let newArray2 = [...displayLogicCondition];
                          newArray2[index].values = value;
                          newArray2[index].answerType = "multiple";
                          setDisplayLogicCondition(newArray2);
                        }}
                        value={
                          forms[index]?.displayLogicValues?.map((ans) => {
                            return forms[forms[index]?.dlogicIndex]?.data?.find(
                              (data) => data?.ids == ans
                            );
                          }) ?? []
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            style={{ width: "350px" }}
                            placeholder="Default Answer"
                          />
                        )}
                      />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Box>
      </Box>
      <Box>
        <span className="boldfont">SKIP LOGIC</span>
        <Box className="logictab">
          <Typography>When answering this Questions</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              flexDirection: "row",
              textTransform: "capitalize",
              gap: 1,
            }}
          >
            <span style={{ fontSize: "16px" }}>If</span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "10px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Select
                      placeholder="yuyuyu"
                      onChange={(e) => {
                        let newArray = [...forms];
                        newArray[index].logicIndex = e.target.value;
                        newArray[index].skipLogicCondition = "";
                        newArray[forms[index]?.logicIndex].skipLogicValue = "";
                        newArray[forms[index]?.logicIndex].skipLogicValues = [];
                        newArray[index].skipLogicJump = "";
                        newArray[index].logicQuestionType =
                          logicQuestionSelecteData[e.target.value].type ==
                          "text"
                            ? "textEmail"
                            : logicQuestionSelecteData[e.target.value].type;
                        setForms(newArray);

                        let newArray2 = [...skipLogicCondition];
                        newArray2[index].questionIndex = e.target.value;
                        newArray2[index].condition = "";
                        newArray2[index].baseIndex = index;
                        newArray2[index].questionType =
                          logicQuestionSelecteData[e.target.value].type;
                        newArray2[index].value = "";
                        newArray2[index].values = [];
                        newArray2[index].answerType = "";

                        setSkipLogicCondition(newArray2);
                      }}
                      value={forms[index].logicIndex}
                    >
                      {/* {logicQuestionSelecteData?.map(
                        (item, i) =>
                          index >= i &&
                          item.type !== "Metrix" && (
                            <MenuItem key={i} value={item.index}>
                              Q{item.index + 1} -{" "}
                              <span style={{ color: "#9F9F9F" }}>
                                {item.name}
                              </span>
                            </MenuItem>
                          )
                      )} */}
                      {forms.map(
                        (form, i) =>
                          form.type !== "Metrix" && (
                            // index <= i && (
                            <MenuItem key={i} value={i}>
                              {" "}
                              Q-{i + 1} &nbsp;
                              <span style={{ color: "#9F9F9F" }}>
                                {form.question
                                  ? form.question.substring(0, 30) + "..."
                                  : ""}
                              </span>
                            </MenuItem>
                          )
                        // )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <Select
                      onChange={(e) => {
                        let newArray = [...forms];
                        newArray[index].skipLogicCondition = e.target.value;
                        newArray[forms[index]?.logicIndex].skipLogicValue = "";
                        newArray[forms[index]?.logicIndex].skipLogicValues = [];
                        setForms(newArray);

                        let newArray2 = [...skipLogicCondition];
                        newArray2[index].condition = e.target.value;
                        newArray2[index].value = "";
                        newArray2[index].values = [];
                        newArray2[index].answerType = "";

                        setSkipLogicCondition(newArray2);
                      }}
                      value={forms[index].skipLogicCondition}
                    >
                      {skipLogicData &&
                      Array.isArray(skipLogicData) &&
                      skipLogicData.length > 0 &&
                      forms[index] &&
                      forms[index].logicQuestionType &&
                      skipLogicData.some(
                        (item) => item.type == forms[index].logicQuestionType
                      )
                        ? skipLogicData
                            .filter(
                              (item) =>
                                item.type === forms[index].logicQuestionType
                            )[0]
                            ?.data.map((item, i) => (
                              <MenuItem key={i} value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    {(forms[index].skipLogicCondition === "Starts with" ||
                      forms[index].skipLogicCondition === "Ends with" ||
                      forms[index].skipLogicCondition === "Contains" ||
                      forms[index].skipLogicCondition === "Does not contain" ||
                      forms[index].skipLogicCondition === "Equal to" ||
                      forms[index].skipLogicCondition === "Not equal to" ||
                      forms[index].skipLogicCondition === "Less than" ||
                      forms[index].skipLogicCondition === "Greater than") && (
                      <TextField
                        type="text"
                        onChange={(e) => {
                          let newArray = [...forms];
                          newArray[index].skipLogicValue = e.target.value;
                          setForms(newArray);

                          let newArray2 = [...skipLogicCondition];
                          newArray2[index].value = e.target.value;
                          setSkipLogicCondition(newArray2);
                        }}
                        value={forms[index].skipLogicValue}
                        placeholder=""
                      />
                    )}
                    {isSkipLogicResponded && isSkipAnswerTypeSingle && (
                      <Select
                        placeholder="yuyuyu"
                        onChange={(e) => {
                          let newArray = [...forms];
                          newArray[index].skipLogicValue = e.target.value;
                          setForms(newArray);

                          let newArray2 = [...skipLogicCondition];
                          newArray2[index].value = e.target.value;
                          newArray2[index].answerType = "single";

                          setSkipLogicCondition(newArray2);
                        }}
                        value={forms[index].skipLogicValue}
                      >
                        {forms[forms[index]?.logicIndex]?.data?.map(
                          (item, i) => (
                            <MenuItem key={i} value={item?.value}>
                              {item.value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    )}
                    {isSkipLogicResponded && isSkipAnswerTypeMultiple && (
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={forms[forms[index]?.logicIndex]?.data.filter(
                          (ans) =>
                            ans.value != "" &&
                            !forms[index]?.defaultAnswer?.includes(ans?.ids)
                        )}
                        getOptionLabel={(option) =>
                          option.value != "" ? option?.value : ""
                        }
                        filterSelectedOptions
                        onChange={(e, value) => {
                          let newArray = [...forms];
                          newArray[index].skipLogicValues = value.map(
                            (val) => val.ids
                          );
                          setForms(newArray);

                          let newArray2 = [...skipLogicCondition];
                          newArray2[index].values = value;
                          newArray2[index].answerType = "multiple";

                          setSkipLogicCondition(newArray2);
                        }}
                        value={forms[index]?.skipLogicValues?.map((ans) => {
                          return forms[forms[index]?.logicIndex]?.data.find(
                            (data) => data.ids === ans
                          );
                        })}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            style={{ width: "350px" }}
                            placeholder="Default Answer"
                          />
                        )}
                      />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            backgroundColor: "gray.main",
            padding: "10px",
            borderRadius: "10px",
            width: "100%",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span className="boldfont" style={{ color: "#fff" }}>
            Then Jump To
          </span>
          <FormControl style={{ flex: 2 }}>
            <Select
              fullWidth
              onChange={(e) => {
                let newArray = [...forms];
                newArray[index].skipLogicJump = e.target.value;
                setForms(newArray);

                let newArray2 = [...skipLogicCondition];
                newArray2[index].jumpTo = e.target.value;
                setSkipLogicCondition(newArray2);
              }}
              value={forms[index].skipLogicJump}
            >
              {forms.map(
                (form, i) =>
                  // forms[index].logicIndex < i && (
                  skipLogicCondition[index].baseIndex < i && (
                    <MenuItem key={i} value={i}>
                      {" "}
                      Q-{i + 1}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </>
  );
};

export default SkipLogic;
